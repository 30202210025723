<template>
  <div>
    <transition name="flixFadeIn">
      <div :key="edit">
        <flixDashboard v-if="!edit">
          <template v-slot:content>
            <div class="flix-container">
              <h3 class="flix-html-h3">{{ $t('message.publish', {name: $tc('message.pageBuilder', 1)}) }}</h3>
              <calendarBreadcrumbs :link="'dashboardPageBuilder'" />
              <transition name="flixFadeIn">
                <div class="flix-form-group" :key="updateKey" v-if="$route.params.id != 'all'">
                  <qrCode :url="getUrl()" />
                  <div class="flix-form-group">
                    <editBtn :onClick="function() { setEdit() }">
                      <template v-slot:text>
                        {{ $t('message.edit', {name: $tc('message.pageBuilder', 1)}) }}
                      </template>
                    </editBtn>
                  </div>
                  <div class="flix-pull-right">
                    <defaultLayout :callback="function () { setEdit() }"/>
                  </div>
                </div>
              </transition>
            </div>
          </template>
        </flixDashboard>
        <pageBuilder :onClose="function(){ changeRoute() }" v-if="edit"/>
      </div>
    </transition>
  </div>
</template>
<script>

export default {
  components: {
    qrCode () { return import('@/components/embed/qrCode.vue') },
    calendarBreadcrumbs () { return import('@/components/assets/calendarBreadcrumbs') },
    pageBuilder () { return import('@/components/pageBuilder/editor') },
    defaultLayout () { return import('./defaultLayout') }
  },
  props: {
  },
  data () {
    return {
      open: false,
      edit: false,
      updateKey: new Date().getTime(),
      variables: {}
    }
  },
  watch: {
    '$route.params.id' () { this.updateKey = new Date().getTime() }
  },
  methods: {
    setEdit () {
      this.edit = true
      var data = Object.assign(this.$route.params, { open: 1 })
      this.$router.push({ name: this.$route.name, data })
    },
    getUrl () {
      return this.$flix_url + '/' + this.$i18n.locale + '/' + this.variables.user.username + '/' + this.$route.params.id + '/'
    },
    changeRoute () {
      this.edit = false
      var data = Object.assign(this.$route.params, { open: 0 })
      this.$router.push({ name: this.$route.name, data })
    }
  },
  mounted () {
    this.variables = this.$getUserVariables()
    if (this.$route.params.open === 1) {
      this.edit = true
    }
  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-link
    word-wrap: break-word
</style>
